import  { SQSClient, GetQueueUrlCommand } from "@aws-sdk/client-sqs";
import AWS from 'aws-sdk'

// Set the AWS Region.
const REGION    = "us-east-1";
const SQS_KEY   = process.env.REACT_APP_SQS_QUEUE;
const S3_BUCKET ='vtxscanner';

// Create SNS service object.
const sqsClient = new SQSClient({
  region: REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET
  }
});

const getSqsQueue = async() => {
  try {
    const data = await sqsClient.send(new GetQueueUrlCommand({QueueName: SQS_KEY}));
    console.log("Success", data);
    return data["QueueUrl"]; // For unit tests.
  } catch (err) {
    console.log(err)
    return null;
  }
}

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET
})

const checkBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION
})

export { sqsClient, getSqsQueue, checkBucket };