import { connect }        from 'react-redux'
import Component          from '../../components/Scanner'
import {
  connectScanner,
  resetScanner,
  startScanner,
  stopScanner,
  verifyScanner
} from '../../../core/scanner'
import { 
  reduxForm,
  formValueSelector
 }      from 'redux-form'
import { validate }       from './validations'

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('scanner')
  const queue_id = selector(state, 'queue_id')
  return {
    initialValues: {
      ip: state.user.ip_address
    },
    status: state.scanner.status,
    loading: state.scanner.loading || false,
    data: state.scanner.data,
    ip: state.scanner.ip_address,
    categories: state.user.categories,
    queue_id: queue_id,
    client_id: state.user.client_id
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    connectScanner: (ip) => dispatch(connectScanner(ip)),
    resetScanner: () => dispatch(resetScanner()),
    startScanner: () => dispatch(startScanner()),
    stopScanner: () => dispatch(stopScanner()),
    verify: (data) => dispatch(verifyScanner(data))
  }
}

const FormComponent = reduxForm({
  form: 'scanner',
  validate: validate
})(Component)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent)
