import React, { Component } from 'react';
import { Field }            from 'redux-form'
import FieldInput           from '../../components/atoms/FieldInput'
import FieldSelect          from '../../components/atoms/FieldSelect'
import Loader               from '../../components/atoms/Loader'
import {
  DragDropContext,
  Droppable,
  Draggable
} from "react-beautiful-dnd";

const grid = 8;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  background: isDragging ? 'lightgreen' : 'grey',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver, even) => ({
  background: isDraggingOver ? 'lightblue' : (even ? 'lightgrey' : 'white'),
  display: 'flex',
  padding: grid,
  overflow: 'auto',
  borderBottom: "1px solid black"
});

class Scanner extends Component {
  state = {
    data: []
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data
      })
    }
  }

  onConnect = (values) => {
    this.props.connectScanner(values.ip)
  }

  verify = () => {
    this.props.verify({
      data: this.state.data,
      queue_id: this.props.queue_id,
      client_id: this.props.client_id
    })
  }

  onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(this.state.data[sInd], source.index, destination.index);
      const newState = [...this.state.data];
      newState[sInd] = items;
      this.setState({
        data: newState
      });
    } else {
      const result = move(this.state.data[sInd], this.state.data[dInd], source, destination);
      const newState = [...this.state.data];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      this.setState({
        data: newState.filter(group => group.length)
      });
    }
  }

  scannerButton() {
    if (this.props.status === "disconnected") {
      return (
        <button
          className='btn button btn-block mt-4'
        >
          Connect
        </button>
      )
    } else if (this.props.status === "start") {
      return (
        <button
          type="button"
          onClick={() => this.props.stopScanner()}
          className="btn button btn-block mt-4"
        >
          Stop Scan
        </button>
      )
    } else if (this.props.status === "stop") {
      return (
        <button
          type="button"
          className="btn button btn-block mt-4"
          onClick={() => this.verify()}
        >
          Verify
        </button>
      )
    } else {
      return (
        <button
          type="button"
          onClick={() => this.props.startScanner()}
          className="btn button btn-block mt-4"
        >
          Start Scan
        </button>
      )
    }
  }

  scannerImages() {
    const { ip } = this.props

    if (this.state.data) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          {this.state.data.map((checks, i) => (
            <Droppable key={i} droppableId={`${i}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver, i % 2 === 0)}
                  {...provided.droppableProps}
                >
                  {checks.map((check, index) => (
                    <Draggable
                      key={check["id"]}
                      draggableId={check["id"]}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="container-image">
                            <img src={`https://${ip}${check["front_image"]}`} alt="check" width="200px" />
                          </div>
                          <div className="container-image">
                            <img src={`https://${ip}${check["rear_image"]}`} alt="check" width="200px" />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {checks.length > 2 && <span className="danger-text">Must be at most a check and coupon!</span>}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      )
    }
  }

  render() {
    const { handleSubmit, loading, status, categories } = this.props

    if (loading) {
      return ( <Loader /> )
    }

    return (
      <div>
        <div className='row mt-5'>
          <div className='col-md-5 mx-auto'>
            <h2 className="text-center">Scan Checks</h2>
            <form
              onSubmit={handleSubmit(this.onConnect)}
              style={{
                border: '1px solid black',
                padding: 20,
                borderRadius: 5
              }}
            >
              <Field
                name="ip"
                type="text"
                readOnly={status !== "disconnected"}
                placeholder="IP Address"
                description={"IP Address located on the scanner"}
                component={FieldInput}
                onClick={() => {
                  this.setState({data: []})
                  this.props.resetScanner();
                }}
              />
              <Field
                name="queue_id"
                component={FieldSelect}
                description={"Queue to be added to PIQ"}
                className="form-control">
                  <option>Please select a Queue</option>
                  {(categories || []).map(c =>
                    <option key={c[1]} value={c[1]}>
                      {c[0]}
                    </option>
                  )}
              </Field>

              {this.scannerImages()}
              {this.scannerButton()}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Scanner
