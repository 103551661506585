import {
  SCANNER_CONNECT,
  SCANNER_CONNECT_SUCCESS,
  SCANNER_CONNECT_ERROR,
  SCANNER_RESET,
  SCANNER_RESET_SUCCESS,
  SCANNER_RESET_ERROR,
  SCANNER_START,
  SCANNER_START_SUCCESS,
  SCANNER_START_ERROR,
  SCANNER_STOP,
  SCANNER_STOP_SUCCESS,
  SCANNER_STOP_ERROR,
  SCANNER_VERIFY,
  SCANNER_VERIFY_SUCCESS,
  SCANNER_VERIFY_ERROR
} from './constants'

export const connectScanner = (data) => ({
  type: SCANNER_CONNECT,
  data
})

export const scannerConnectSuccess = (data) => ({
  type: SCANNER_CONNECT_SUCCESS,
  data
})

export const scannerConnectError = (data) => ({
  type: SCANNER_CONNECT_ERROR,
  data
})

export const resetScanner = () => ({
  type: SCANNER_RESET
})

export const scannerResetSuccess = () => ({
  type: SCANNER_RESET_SUCCESS,
})

export const scannerResetError = () => ({
  type: SCANNER_RESET_ERROR
})

export const startScanner = () => ({
  type: SCANNER_START
})

export const scannerStartSuccess = (data) => ({
  type: SCANNER_START_SUCCESS,
  data
})

export const scannerStartError = (data) => ({
  type: SCANNER_START_ERROR,
  data
})

export const stopScanner = () => ({
  type: SCANNER_STOP
})

export const scannerStopSuccess = (data) => ({
  type: SCANNER_STOP_SUCCESS,
  data
})

export const scannerStopError = (data) => ({
  type: SCANNER_STOP_ERROR,
  data
})

export const verifyScanner = (data) => ({
  type: SCANNER_VERIFY,
  data
})

export const scannerVerifySuccess = () => ({
  type: SCANNER_VERIFY_SUCCESS
})

export const scannerVerifyError = (data) => ({
  type: SCANNER_VERIFY_ERROR,
  data
})