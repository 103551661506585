import { connect }        from 'react-redux'
import Component          from '../../components/Login'
import { ssoLogin }          from '../../../core/user'
import { reduxForm }      from 'redux-form'

const mapStateToProps = (state, ownProps) => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLogin: (user) => dispatch(ssoLogin(user))
  }
}

const FormComponent = reduxForm({
  form: 'login'
})(Component)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent)
