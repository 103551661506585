import fetch     from 'isomorphic-fetch'
import {
  getResponseData,
  getResponse
} from '../api/helpers'
import { config } from './config';

export default {

  connectScanner: (ip_address) => {
    return fetch(`https://${ip_address}/connect.json`, {
      method: 'GET',
      referrerPolicy: 'unsafe-url',
      headers: {
        'Content-Type': 'application/json',
        'AppInstId': "84497bbc-fb86-4d0a-ba38-2a93ee4d6577"
      }
    }).then(resp => getResponseData(resp))
  },
  updateConfig: (ip_address) => {
    return fetch(`https://${ip_address}/config.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'AppInstId': "84497bbc-fb86-4d0a-ba38-2a93ee4d6577"
      },
      body: JSON.stringify(config())
    }).then(resp => resp)
  },
  startScanner: (ip_address) => {
    return fetch(`https://${ip_address}/scan`, {
      method: 'GET',
      headers: {
        'AppInstId': "84497bbc-fb86-4d0a-ba38-2a93ee4d6577"
      }
    }).then(resp => getResponse(resp))
  },
  resetScanner: (ip_address) => {
    return fetch(`https://${ip_address}/reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'AppInstId': "Panini-012345678"
      }
    }).then(resp => resp)
  },
  stopScanner: (ip_address) => {
    return fetch(`https://${ip_address}/getitemlist.json`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'AppInstId': "84497bbc-fb86-4d0a-ba38-2a93ee4d6577"
      }
    }).then(resp => getResponseData(resp))
  }
}
