import React, { Component } from 'react';
import logo                 from '../../assets/logo.png'
import { getUrlParameter }  from '../../../utils/custom_services'

export default class LoginScreen extends Component {

  componentDidMount() {
    this.ssoLogin()
  }

  ssoLogin() {
    var token = getUrlParameter('data').replace(/\s/g, '')

    if(token) {
      this.props.onLogin(token)
      this.props.history.push('/')
    }
  }

  render() {

    return (
      <div>
        <div className='row'>
          <div className='col-md-4 mx-auto'>
            <div className='mx-auto text-center'>
              <div className='company_name'>
                <img src={logo} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
