import React, { Component }           from 'react'
import { connect }                    from 'react-redux'
import { withRouter }  from 'react-router-dom'
import {
  Button,
  Navbar
} from 'reactstrap'
import { unsetUser }                  from '../../../core/user'
import logo                           from '../../assets/logo.png'

class MainLayout extends Component {

  componentDidMount() {
    // this.props.getNotifications();
  }

  handleLogout = () => {
    const { logout } = this.props
    logout();
  }

  render() {

    return (
      <div>
        <Navbar className="navbar navbar-fixed-top">
          <a href="/" className="navbar-brand" rel="noopener noreferrer" target="_blank" style={{color: 'white'}}>
            <div id="logo">
              <img src={logo} alt="logo" />
            </div>
          </a>
          <div>
            <Button onClick={this.handleLogout} className="btn btn-sm btn-outline-secondary" style={{color: '#012638', borderColor: '#012638'}}>
              Logout
            </Button>
          </div>
        </Navbar>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
   );
 }
}

//  CONNECT
const mapStateToProps = (state, ownProps) => {
  return {
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    logout: () => dispatch(unsetUser())
  }
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout))
