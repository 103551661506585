import React                        from 'react'
import { InputGroup, InputGroupAddon, Label, Input, Button }  from 'reactstrap'

const FieldInput = ({ input, type, label, required, placeholder, children, onClick, className, description, readOnly, meta: { touched, visited, error, warning } }) => {

  return (
    <div>
      <InputGroup>
        <Label for={input.name}>
          {required && <abbr title="required">*</abbr> }
          {label}
        </Label>
        <Input
          {...input}
          id={input.name}
          type={type}
          placeholder={placeholder}
          readOnly={readOnly}
          className={`form-control ${touched && error ? 'is-invalid' : ''} ${className}`}>
            {children}
        </Input>
        <InputGroupAddon addonType="append">
          <Button
            color="danger"
            onClick={onClick}
          >
            Reset
          </Button>
        </InputGroupAddon>
      </InputGroup>
      { description && <span className="description">{description}</span>}
        {touched &&
          ((error && <span className="danger-text">{error}</span>) ||
            (warning && <span className="warning-text">{warning}</span>))}
    </div>
    
  )
}

export default FieldInput
