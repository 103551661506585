import {
  USER_SET,
  USER_UNSET,
  SSO_LOGIN_REQUESTED
} from './constants'

const initial = {
  loading: false,
  categories: null,
  ip_address: null,
  access_token: null,
  client_id: null
}

export const userReducer = (state = initial, action) => {
  switch (action.type) {
    case SSO_LOGIN_REQUESTED:
      return {
        ...state,
        loading: true
      }

    case USER_SET:
      return {
        ...state,
        loading: false,
        categories: action.token.categories,
        ip_address: action.token.ip_address,
        client_id: action.token.client_id,
        access_token: action.token
      }

    case USER_UNSET:
      return initial

    default:
      return state
  }
}
