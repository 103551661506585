var inflection = require( 'inflection' );

export const formatPhoneNumber = (phone) => {
  var s2 = (""+phone).replace(/\D/g, '');
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? null : m[1] + "-" + m[2] + "-" + m[3];
}

export const ordinal_suffix_of = (i) => {
  var j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}

export const titleize = (slug) => {
  var words = slug.split("_");
  return words.map(function(word) {
      return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
  }).join(' ');
}

export const displayErrors = (errors) => {
	var messages = '';

	if(typeof errors === 'object') {
		for(var index in errors) {
	    	messages += inflection.titleize(index) + ' '+ errors[index] + "\n";
		}
	} else {
		messages = errors;
	}

	return messages
}

export const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export const formatAmount = (amount) => {
	let formatted_amount = Number(amount / 100).toFixed(2)

	return '$'+formatted_amount
}

export const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(window.location.search);

  return results === null ? '' : decodeURIComponent(results[1]);
};
