import {
  USER_SET,
  USER_UNSET,
  SSO_LOGIN_REQUESTED,
} from './constants'

export const setUser = (token) => ({
  type: USER_SET,
  token
})

export const unsetUser = () => ({
  type: USER_UNSET
})

export const ssoLogin = (jwt) => ({
  type: SSO_LOGIN_REQUESTED,
  jwt
})
