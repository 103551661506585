import React                        from 'react'
import { Label, Input }  from 'reactstrap'

const FieldSelect = ({ input, type, label, required, description, children, className, meta: { touched, visited, error, warning } }) => {
  return (
    <div>
      { label &&
        <Label for={input.name}>
          {required && <abbr title="required">*</abbr> }
          {label}
        </Label>
      }
      <Input
        {...input}
        style={{marginTop: 20}}
        id={input.name}
        type="select"
        className={`form-control ${touched && error ? 'is-invalid' : ''} ${className}`}>
          {children}
      </Input>
      { description && <span className="description">{description}</span>}
      { touched &&
        ((error && <span className="danger-text">{error}</span>) ||
          (warning && <span className="warning-text">{warning}</span>))}
    </div>
  )
}

export default FieldSelect
