export const SCANNER_CONNECT             = 'SCANNER_CONNECT'
export const SCANNER_CONNECT_SUCCESS     = 'SCANNER_CONNECT_SUCCESS'
export const SCANNER_CONNECT_ERROR       = 'SCANNER_CONNECT_ERROR'

export const SCANNER_RESET               = 'SCANNER_RESET'
export const SCANNER_RESET_SUCCESS       = 'SCANNER_RESET_SUCCESS'
export const SCANNER_RESET_ERROR         = 'SCANNER_RESET_ERROR'

export const SCANNER_START               = 'SCANNER_START'
export const SCANNER_START_SUCCESS       = 'SCANNER_START_SUCCESS'
export const SCANNER_START_ERROR        = 'SCANNER_START_ERROR'

export const SCANNER_STOP               = 'SCANNER_STOP'
export const SCANNER_STOP_SUCCESS       = 'SCANNER_STOP_SUCCESS'
export const SCANNER_STOP_ERROR = 'SCANNER_STOP_ERROR'

export const SCANNER_VERIFY               = 'SCANNER_VERIFY'
export const SCANNER_VERIFY_SUCCESS       = 'SCANNER_VERIFY_SUCCESS'
export const SCANNER_VERIFY_ERROR         = 'SCANNER_VERIFY_ERROR'
