import {
  all,
  takeLatest,
  fork,
  call,
  put
} from 'redux-saga/effects'
import {
  scannerConnectError,
  scannerConnectSuccess,
  scannerResetSuccess,
  scannerResetError,
  scannerStartSuccess,
  scannerStartError,
  scannerStopError,
  scannerStopSuccess,
  scannerVerifyError,
  scannerVerifySuccess
} from './actions'
import {
  SCANNER_CONNECT,
  SCANNER_RESET,
  SCANNER_START,
  SCANNER_STOP,
  SCANNER_VERIFY
} from './constants'
import api        from './api'
import { toastr } from 'react-redux-toastr'
import { SendMessageCommand } from  "@aws-sdk/client-sqs";
import { sqsClient, getSqsQueue, checkBucket } from  '../api/sqsClient'
import axios from 'axios'
import jwt_decode from 'jwt-decode'

const S3_BUCKET ='vtxscanner';
const S3_KEY = process.env.REACT_APP_S3_KEY;

const setIpAddress = (ip) => {
  localStorage.setItem('ip_address', ip)
}

const getIpAddress = () => {
  return localStorage.getItem('ip_address')
}


const client_id = () => {
  let token = localStorage.getItem('token')
  let decoded_token = jwt_decode(token)
  return decoded_token["client_id"]
}

const uploadFile = async(file, date_time) => {
  try {
    var url = null;
    let front_image = file.split("/").pop()
    let key = `${S3_KEY}/${client_id()}/${date_time}/${front_image}`
    
    const body = await axios.get(`https://${getIpAddress()}${file}`, { responseType: 'arraybuffer' })
      .then(response => response.data)
      .catch(error => {
        console.error(error);
      });
    const params  = {
      ACL: 'private',
      Body: body,
      Bucket: S3_BUCKET,
      Key: key
    };

    let response = checkBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {})
      .send((err, data) => {})

    if (response["error"] === null) {
      url = checkBucket.getSignedUrl('getObject', { Bucket: S3_BUCKET, Key: key, Expires: 7200 });
    }
  
    return url
  } catch (error) {
    console.error(error)
  }
}

const sortChecks = (data) => {
  var coupon_index  = data.findIndex(obj => obj["check_type"] === "coupon")
  var check_index = data.findIndex(obj => obj["check_type"] === "check")
  var coupon = coupon_index >= 0;
  
  //  Check for bad checks
  if(check_index === -1){
    return {
      "Type": data[0]["type"],
      "check_type": "check",
      "MICR": data[0]["micr"],
      "OCRRead": data[1] ? data[1]["ocr_read"] : null,
      "front_image_check": data[0]["front_tiff"],
      "rear_image_check": data[0]["rear_tiff"],
      "front_image_coupon": data[1] ? data[1]["front_tiff"] : null,
      "rear_image_coupon": data[1] ? data[1]["rear_tiff"] : null
    }
  }
  
  return {
    "Type": data[0]["type"],
    "check_type": "check",
    "MICR": data[check_index]["micr"],
    "OCRRead": coupon ? data[coupon_index]["ocr_read"] : null,
    "front_image_check": data[check_index]["front_tiff"],
    "rear_image_check": data[check_index]["rear_tiff"],
    "front_image_coupon": coupon ? data[coupon_index]["front_tiff"] : null,
    "rear_image_coupon": coupon ? data[coupon_index]["rear_tiff"] : null
  }
}

const sendMessage = async (message_body) => {
  try {
    let queue_url = await getSqsQueue()
    console.log(queue_url)
    const params = {
      DelaySeconds: 1,
      MessageBody: JSON.stringify(message_body),
      QueueUrl: queue_url
    };
    console.log(params)
    await sqsClient.send(new SendMessageCommand(params));
  } catch (error) {
    console.log(error)
  }
}

function* scannerConnectFlow(action) {
  try {
    const { data } = action
    yield call(api.connectScanner, data)
    console.log("connected to scanner")
    yield call(setIpAddress, data)
    yield call(api.updateConfig, data)
    yield put(scannerConnectSuccess(data))
    toastr.success('Success', 'Scanner Connected!')
  } catch (error) {
    console.log(error)
    yield put(scannerConnectError(error))
    toastr.error('Error', 'Could not Connect')
  }
}

function* scannerResetFlow(action) {
  try {
    const ip = yield call(getIpAddress)
    yield call(api.resetScanner, ip)
    
    yield put(scannerResetSuccess())
    toastr.success('Success', 'Scanner Reset!')
  } catch (error) {
    console.log(error)
    yield put(scannerResetError())
    toastr.error('Error', 'Could not Reset')
  }
}

function* scannerStartFlow(action) {
  try {
    const ip = yield call(getIpAddress)
    const response = yield call(api.startScanner, ip)
    if(!response) throw new Error("invalid")
    yield put(scannerStartSuccess())
    toastr.success('Success', 'Scanning Started!')
  } catch (error) {
    console.log(error)
    yield put(scannerStartError())
    toastr.error('Error', 'Could not start scanning')
  }
}

function* scannerStopFlow(action) {
  try {
    const ip = yield call(getIpAddress)
    const response = yield call(api.stopScanner, ip)
    yield put(scannerStopSuccess(response))
    toastr.success('Success', 'Scanning Stopped!')
  } catch (error) {
    console.log(error)
    yield put(scannerStopError())
    toastr.error('Error', 'Could not stop scanning')
  }
}

function* scannerVerifyFlow(action) {
  var sorted_checks = []
  const date_time   = new Date().getTime()
  var data          = action.data.data;
  try {
    for (var i = 0; i < data.length; i++) {
      for (var x = 0; x < data[i].length; x++) {
        let check                 = data[i][x]
        let front_image           = yield call(uploadFile, check["front_tiff"], date_time)
        let rear_image            = yield call(uploadFile, check["rear_tiff"], date_time)
        data[i][x]["front_tiff"]  = front_image;
        data[i][x]["rear_tiff"]   = rear_image;
      }
      let response = yield call(sortChecks, data[i])
      sorted_checks.push(response)
    }
    action.data.data = sorted_checks
    console.log(action.data)
    yield call(sendMessage, action.data)
    yield put(scannerVerifySuccess())
    toastr.success('Success', 'Images Uploaded!')
  } catch (error) {
    console.log(error)
    yield put(scannerVerifyError())
    toastr.error('Error', 'Could not upload images')
  }
}

function* scannerWatcher() {
  yield all([
    takeLatest(SCANNER_CONNECT, scannerConnectFlow),
    takeLatest(SCANNER_RESET, scannerResetFlow),
    takeLatest(SCANNER_START, scannerStartFlow),
    takeLatest(SCANNER_STOP, scannerStopFlow),
    takeLatest(SCANNER_VERIFY, scannerVerifyFlow)
  ])
}

//=====================================
//  ROOT
//-------------------------------------

export const scannerSagas = [
  fork(scannerWatcher)
];
