export const config = () => {
    return {
        "ScanningConfiguration": {
            "DeviceParameters": {
                "General": {
                    "FeedingMode": "DROP",
                    "OneDocMode": "FALSE",
                    "SorterParameters": {
                        "DFDEnabled": "FALSE",
                        "TapeEnabled": "FALSE",
                        "VLDEnabled": "FALSE",
                        "AutoOrientation": "FALSE",
                        "AutoDeskew": "FALSE",
                        "IESEnabled": "FALSE"
                    }
                },
                "MICR": {
                    "Enabled": "TRUE",
                    "Font": "E13B",
                    "RejectSymbol": "?",
                    "Spaces": "NONE"
                },
                "Imaging": {
                    "Checks": {
                        "FrontImage1": {
                            "Format": "GIV",
                            "Resolution": "200",
                            "Threshold": "3"
                        },
                        "FrontImage2": {
                            "Format": "JPEG",
                            "Resolution": "300",
                            "Threshold": "75"
                        },
                        "RearImage1": {
                            "Format": "GIV",
                            "Resolution": "200",
                            "Threshold": "3"
                        },
                        "RearImage2": {
                            "Format": "JPEG",
                            "Resolution": "200",
                            "Threshold": "3"
                        },
                        "Snippets": {
                            "Snippet1": {
                                "Enabled": "TRUE",
                                "Front": "TRUE",
                                "XPos": "0",
                                "YPos": "0",
                                "Width": "600",
                                "Height": "20",
                                "Orientation": "0",
                                "Millimeters": "TRUE",
                                "Color": "GL",
                                "OCR": {
                                    "Font": "1",
                                    "TimeOut": "6000"
                                }
                            },
                            "Snippet2": {
                                "Enabled": "FALSE",
                                "Front": "TRUE",
                                "XPos": "0",
                                "YPos": "0",
                                "Width": "0",
                                "Height": "0",
                                "Orientation": "0",
                                "Millimeters": "TRUE",
                                "Color": "GL",
                                "OCR": {
                                    "Font": "0",
                                    "TimeOut": "3000"
                                }
                            },
                            "Snippet3": {
                                "Enabled": "FALSE",
                                "Front": "TRUE",
                                "XPos": "0",
                                "YPos": "0",
                                "Width": "0",
                                "Height": "0",
                                "Orientation": "0",
                                "Millimeters": "TRUE",
                                "Color": "GL",
                                "OCR": {
                                    "Font": "0",
                                    "TimeOut": "3000"
                                }
                            }
                        }
                    },
                    "Cards": {
                        "FrontImage1": {
                            "Format": "JPEG",
                            "Resolution": "300",
                            "Threshold": "75"
                        },
                        "FrontImage2": {
                            "Format": "NONE",
                            "Resolution": "300",
                            "Threshold": "3"
                        },
                        "RearImage1": {
                            "Format": "JPEG",
                            "Resolution": "200",
                            "Threshold": "75"
                        },
                        "RearImage2": {
                            "Format": "NONE",
                            "Resolution": "200",
                            "Threshold": "3"
                        },
                        "Snippets": {
                            "Snippet1": {
                                "Enabled": "FALSE",
                                "Front": "TRUE",
                                "XPos": "0",
                                "YPos": "0",
                                "Width": "0",
                                "Height": "0",
                                "Orientation": "0",
                                "Millimeters": "TRUE",
                                "Color": "GL",
                                "OCR": {
                                    "Font": "0",
                                    "TimeOut": "3000"
                                }
                            },
                            "Snippet2": {
                                "Enabled": "FALSE",
                                "Front": "TRUE",
                                "XPos": "0",
                                "YPos": "0",
                                "Width": "0",
                                "Height": "0",
                                "Orientation": "0",
                                "Millimeters": "TRUE",
                                "Color": "GL",
                                "OCR": {
                                    "Font": "0",
                                    "TimeOut": "3000"
                                }
                            },
                            "Snippet3": {
                                "Enabled": "FALSE",
                                "Front": "TRUE",
                                "XPos": "0",
                                "YPos": "0",
                                "Width": "0",
                                "Height": "0",
                                "Orientation": "0",
                                "Millimeters": "TRUE",
                                "Color": "GL",
                                "OCR": {
                                    "Font": "0",
                                    "TimeOut": "3000"
                                }
                            }
                        }
                    }
                },
                "Printing": {
                    "Checks": {
                        "PrintArea1": {
                            "Enabled": "FALSE",
                            "PrintType": "LEADING",
                            "PrintFont": {
                                "FontSize": "18",
                                "FontFamily": "HERSHEY_SIMPLEX",
                                "FontBold": "FALSE"
                            },
                            "PrintText": "",
                            "PrintTextPosition": "0",
                            "PrintImage": "",
                            "PrintImagePosition": "0",
                            "PrintQuality": "HIGHQ"
                        },
                        "PrintArea2": {
                            "Enabled": "FALSE",
                            "PrintType": "LEADING",
                            "PrintFont": {
                                "FontSize": "18",
                                "FontFamily": "HERSHEY_SIMPLEX",
                                "FontBold": "FALSE"
                            },
                            "PrintText": "",
                            "PrintTextPosition": "0",
                            "PrintImage": "",
                            "PrintImagePosition": "0",
                            "PrintQuality": "HIGHQ"
                        },
                        "PrintArea3": {
                            "Enabled": "FALSE",
                            "PrintType": "LEADING",
                            "PrintFont": {
                                "FontSize": "18",
                                "FontFamily": "HERSHEY_SIMPLEX",
                                "FontBold": "FALSE"
                            },
                            "PrintText": "",
                            "PrintTextPosition": "0",
                            "PrintImage": "",
                            "PrintImagePosition": "0",
                            "PrintQuality": "HIGHQ"
                        },
                        "PrintArea4": {
                            "Enabled": "FALSE",
                            "PrintType": "LEADING",
                            "PrintFont": {
                                "FontSize": "18",
                                "FontFamily": "HERSHEY_SIMPLEX",
                                "FontBold": "FALSE"
                            },
                            "PrintText": "",
                            "PrintTextPosition": "0",
                            "PrintImage": "",
                            "PrintImagePosition": "0",
                            "PrintQuality": "HIGHQ"
                        },
                        "Sequence": {
                            "Enabled": "FALSE",
                            "LeftPaddedSequenceLength": "0",
                            "LeftPaddingCharacter": " ",
                            "RightPaddedSequenceLength": "0",
                            "RightPaddingCharacter": " "
                        }
                    },
                    "SmartJet": {
                        "Enabled": "FALSE",
                        "PrintType": "LEADING",
                        "WaitTimeout": "300"
                    }
                },
                "Pocketing": {
                    "Checks": {
                        "DefaultPocket": "1",
                        "SmartPocketWaitingTimeout": "300"
                    }
                },
                "SmartScript": {}
            }
        }
    }
}