import React, { Component }         from 'react'
import { Provider }                 from 'react-redux'
import { BrowserRouter as Router, Route }    from 'react-router-dom'
import history                      from './utils/history'
import configureStore               from './core/store'
import MainLayout                   from './views/components/MainLayout'
import Login                        from './views/containers/Login'
import Scanner                      from './views/containers/Scanner'
import AuthorizedRoute              from './views/components/RouteHelper/AuthorizedRoute'
import ReduxToastr                  from 'react-redux-toastr'
import { checkIndexAuthorization }  from './core/api'

const store = configureStore();
const isAuthorized = () => checkIndexAuthorization(store);

class App extends Component {
  render() {
    return (
        <Provider store={store}>
          <div>
            <ReduxToastr
              timeOut={6000}
              newestOnTop={true}
              preventDuplicates
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar/>

            <Router history={history} >
                <AuthorizedRoute
                  exact path="/"
                  layout={MainLayout}
                  component={Scanner}
                  isAuthorized={isAuthorized}
                  props={{}} />
                <Route exact path="/login" component={Login} />
                
            </Router>
          </div>

        </Provider>
    );
  }
}

export default App
