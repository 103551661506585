import {
  SCANNER_CONNECT, SCANNER_CONNECT_SUCCESS, SCANNER_CONNECT_ERROR,
  SCANNER_RESET, SCANNER_RESET_SUCCESS, SCANNER_RESET_ERROR,
  SCANNER_START, SCANNER_START_SUCCESS, SCANNER_START_ERROR,
  SCANNER_STOP, SCANNER_STOP_SUCCESS, SCANNER_STOP_ERROR,
  SCANNER_VERIFY, SCANNER_VERIFY_SUCCESS, SCANNER_VERIFY_ERROR
} from './constants'

const initial = {
  loading: false,
  ip_address: null,
  status: "disconnected",
  data: null
}

export const scannerReducer = (state = initial, action) => {
  switch (action.type) {

    case SCANNER_CONNECT:
      return {
        ...state,
        loading: true
      }

    case SCANNER_CONNECT_SUCCESS:
      console.log(action)
      return {
        ...state,
        ip_address: action.data,
        status: "connected",
        loading: false
      }

    case SCANNER_CONNECT_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    
    case SCANNER_RESET:
      return {
        ...state,
        loading: true
      }

    case SCANNER_RESET_SUCCESS:
      return {
        ...state,
        data: null,
        status: "disconnected",
        loading: false
      }

    case SCANNER_RESET_ERROR:
      return {
        ...state,
        data: null,
        loading: false
      }
    
    case SCANNER_START:
      return {
        ...state,
        loading: true
      }

    case SCANNER_START_SUCCESS:
      return {
        ...state,
        status: "start",
        loading: false
      }

    case SCANNER_START_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    
    case SCANNER_STOP:
      return {
        ...state,
        loading: true
      }

    case SCANNER_STOP_SUCCESS:
      var ordered_checks = []
      console.log(action.data)
      const checks = action.data.map(function (c) {
        return {
          id: c["ID"],
          type: c["Type"],
          check_type: ((c["MICR"].includes("??") || c["MICR"] === "") ? "coupon" : "check"),
          micr: c["MICR"],
          ocr_read: c["Snippet1"]["OCRRead"],
          front_image: c["FrontImage2"],
          rear_image: c["RearImage2"],
          front_tiff: c["FrontImage1"],
          rear_tiff: c["RearImage1"]
        }
      })

      for (var i = 0; i < checks.length; i++) {
        var p = i - 1;
        var l = i - 2;
        if (i === 0 ||
          (p >= 0 && checks[i]["check_type"] === checks[p]["check_type"]) ||
          (l >= 0 && checks[i]["check_type"] === checks[l]["check_type"] && checks[i]["check_type"] !== "coupon")) {
          ordered_checks.push([checks[i]])
        } else {
          var length = ordered_checks.length - 1;
          ordered_checks[length].push(checks[i])
        }
      }
     
      return {
        ...state,
        data: ordered_checks,
        status: "stop",
        loading: false
      }

    case SCANNER_STOP_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    
    case SCANNER_VERIFY:
      return {
        ...state,
        loading: true
      }

    case SCANNER_VERIFY_SUCCESS:
      return {
        ...state,
        data: null,
        status: "connected",
        loading: false
      }

    case SCANNER_VERIFY_ERROR:
      return {
        ...state,
        loading: false
      }

    default:
      return state
  }
}
