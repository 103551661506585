import { all } from 'redux-saga/effects'

import { scannerSagas }          from './scanner'
import { userSagas }             from './user'

export default function* sagas() {
  yield all([
    ...scannerSagas,
    ...userSagas,
  ]);
}
