import { combineReducers }                             from 'redux'
import { userReducer } from './user'
import { scannerReducer } from './scanner'
import { reducer as formReducer }                      from 'redux-form'
import { reducer as toastrReducer }                    from 'react-redux-toastr'

function lastAction(state = null, action) {
  return action
}

export default combineReducers({
  scanner: scannerReducer,
  user: userReducer,
  form: formReducer,
  toastr: toastrReducer,
  lastAction
})
