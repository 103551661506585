import {
  all,
  takeLatest,
  fork,
  call,
  put
} from 'redux-saga/effects'
import {
  setUser,
} from './actions'
import {
  USER_UNSET,
  SSO_LOGIN_REQUESTED
} from './constants'
import history     from '../../utils/history'

import jwt_decode from 'jwt-decode'

const setAuthToken = (token) => {
  localStorage.setItem('token', JSON.stringify(token))
}

const removeAuthToken = () => {
  localStorage.removeItem('token')
}

function* logout () {
  yield call(removeAuthToken)
  history.push('/login')
}

function* authorize(response) {
  try {
    const jwt = response.jwt
    var token = jwt_decode(jwt)
    if (token) {
      yield call(setAuthToken, response.jwt)
      yield put(setUser(token))
      history.push('/');
      return token
    } else {
      yield call(logout)
      return null
    }
  } catch (error) {
    console.error(error)
  }
}

function* userWatcher() {
  yield all([
    takeLatest(USER_UNSET, logout),
    takeLatest(SSO_LOGIN_REQUESTED, authorize),
  ])
}

//=====================================
//  ROOT
//-------------------------------------

export const userSagas = [
  fork(userWatcher)
];
