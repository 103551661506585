export const validate = (values) => {

  const errors = {}
  if(!values.ip) {
    errors.ip = 'Required'
  }

  if (values.ip) {
    if (!(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(values.ip))) {
      errors.ip = "You have entered an invalid IP address!"
    }
  }

  return errors
}
